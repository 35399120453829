import { EventEmitter } from 'events';
import { isNumber } from 'lodash';

export enum PlayerLibStreamPhase {
    Pre = 1,
    Session = 2,
    Post = 3,
}
export type PlayerLibStream = {
    id: string; // const char*
    phase: PlayerLibStreamPhase; // enum WpPlayerLibPhase
    url: string; // const char*
    fromTime: number; // uint64_t
    toTime: number; // uint64_t
    fadeOutTime: number; // uint64_t
    loopContent: boolean; // uint8_t
    gain: number; // float
    usesSidechain: boolean; // bool
    sidechainGain: number; // float
};

export class PlayerLibInterface extends EventEmitter {
    private static playerWasmCodeP: Promise<any> | null = null;
    private playerWasmP: Promise<any> | null = null;
    private playerRef: number | null = null;

    private broadcastElapsedTimeSecs = 0;

    constructor() {
        super();
        if (PlayerLibInterface.playerWasmCodeP === null) {
            PlayerLibInterface.playerWasmCodeP = import(
                /* webpackIgnore: true */ '/playerlib/WpPlayerlibWasm.js' as any
            );
        }
        this.playerWasmP = PlayerLibInterface.playerWasmCodeP.then((module) => module.default());
    }

    async init() {
        const playerWasm = await this.playerWasmP;
        // playerWasm.emscriptenRegisterAudioObject(audioCtx);
        if ('audioSession' in navigator) {
            (navigator as any).audioSession.type = 'playback';
        }
        this.playerRef = await playerWasm.ccall('wp_playerlib_wasm_create', 'number', ['number'], [48000], {
            async: true,
        });
    }

    audioCtx(): Promise<AudioContext> {
        const windowWeak = window as any;
        const miniaudioCtx = windowWeak.miniaudio?.get_device_by_index(0).webaudio;
        if (!miniaudioCtx) {
            throw new Error('No miniaudio context found');
        }
        return miniaudioCtx;
    }

    async setSession(streams: PlayerLibStream[]) {
        if (!isNumber(this.playerRef)) {
            throw new Error('Player not initialized');
        }
        const playerWasm = await this.playerWasmP;
        try {
            const streamsPtr = playerWasm.ccall(
                'wp_playerlib_wasm_malloc_streams',
                'number',
                ['number'],
                [streams.length],
            );
            const stringPtrs: number[] = [];
            streams.forEach(
                (
                    { id, url, phase, fromTime, toTime, fadeOutTime, loopContent, gain, usesSidechain, sidechainGain },
                    i,
                ) => {
                    const idPtr = playerWasm._malloc(id.length + 1);
                    playerWasm.stringToUTF8(id, idPtr, id.length + 1);
                    const urlPtr = playerWasm._malloc(url.length + 1);
                    playerWasm.stringToUTF8(url, urlPtr, url.length + 1);
                    playerWasm.ccall(
                        'wp_playerlib_wasm_set_stream',
                        'void',
                        [
                            'number',
                            'number',
                            'number',
                            'number',
                            'number',
                            'number',
                            'number',
                            'number',
                            'number',
                            'number',
                            'number',
                            'number',
                        ],
                        [
                            streamsPtr,
                            i,
                            idPtr,
                            phase,
                            urlPtr,
                            BigInt(Math.round(fromTime)),
                            BigInt(Math.round(toTime)),
                            BigInt(Math.round(fadeOutTime)),
                            loopContent ? 1 : 0,
                            gain,
                            usesSidechain ? 1 : 0,
                            sidechainGain,
                        ],
                        { async: true },
                    );
                    stringPtrs.push(idPtr, urlPtr);
                },
            );
            await playerWasm.ccall(
                'wp_playerlib_wasm_set_session',
                'void',
                ['number', 'number', 'number'],
                [this.playerRef, streamsPtr, streams.length],
                { async: true },
            );
            stringPtrs.forEach((ptr) => playerWasm._free(ptr));
            playerWasm._free(streamsPtr);
        } catch (e) {
            console.error(e);
        }
    }

    setBroadcastElapsedTimeSecs(secs: number) {
        this.broadcastElapsedTimeSecs = secs;
    }

    async getPhase(): Promise<PlayerLibStreamPhase> {
        if (!isNumber(this.playerRef)) {
            throw new Error('Player not initialized');
        }
        const playerWasm = await this.playerWasmP;
        return playerWasm.ccall('wp_playerlib_wasm_get_phase', 'number', ['number'], [this.playerRef], { async: true });
    }

    async getTimeInPhase(): Promise<number> {
        if (!isNumber(this.playerRef)) {
            throw new Error('Player not initialized');
        }
        const playerWasm = await this.playerWasmP;
        const timeMs = await playerWasm.ccall(
            'wp_playerlib_wasm_get_time_in_phase',
            'bigint',
            ['number'],
            [this.playerRef],
            {
                async: true,
            },
        );
        return Number(timeMs) / 1000;
    }

    async setPhase(phase: PlayerLibStreamPhase) {
        if (!isNumber(this.playerRef)) {
            throw new Error('Player not initialized');
        }
        let timeWithinPhase = 0;
        if (phase === PlayerLibStreamPhase.Session) {
            timeWithinPhase = this.broadcastElapsedTimeSecs * 1000;
        }
        const playerWasm = await this.playerWasmP;
        await playerWasm.ccall(
            'wp_playerlib_wasm_set_phase',
            'void',
            ['number', 'number', 'number'],
            [this.playerRef, phase, BigInt(Math.round(timeWithinPhase))],
            { async: true },
        );
    }

    async seekToTimeInPhase(timeWithinPhase: number) {
        if (!isNumber(this.playerRef)) {
            throw new Error('Player not initialized');
        }
        const playerWasm = await this.playerWasmP;
        await playerWasm.ccall(
            'wp_playerlib_wasm_seek_to_time_in_phase',
            'void',
            ['number', 'number'],
            [this.playerRef, BigInt(Math.round(timeWithinPhase * 1000))],
            {
                async: true,
            },
        );
    }

    async setVolume(volume: number) {
        if (!isNumber(this.playerRef)) {
            throw new Error('Player not initialized');
        }
        const playerWasm = await this.playerWasmP;
        await playerWasm.ccall('wp_playerlib_wasm_set_volume', 'void', ['number', 'number'], [this.playerRef, volume], {
            async: true,
        });
    }

    async getBufferedTime(): Promise<number> {
        if (!isNumber(this.playerRef)) {
            throw new Error('Player not initialized');
        }
        const playerWasm = await this.playerWasmP;
        return playerWasm.ccall('wp_playerlib_wasm_get_buffered_time', 'number', ['number'], [this.playerRef], {
            async: true,
        });
    }

    async start() {
        if (!isNumber(this.playerRef)) {
            throw new Error('Player not initialized');
        }
        const playerWasm = await this.playerWasmP;
        await playerWasm.ccall('wp_playerlib_wasm_start', 'void', ['number'], [this.playerRef], { async: true });
    }

    async stop() {
        if (!isNumber(this.playerRef)) {
            throw new Error('Player not initialized');
        }
        const playerWasm = await this.playerWasmP;
        await playerWasm.ccall('wp_playerlib_wasm_stop', 'void', ['number'], [this.playerRef], { async: true });
    }

    async isStarted() {
        if (!isNumber(this.playerRef)) {
            throw new Error('Player not initialized');
        }
        const playerWasm = await this.playerWasmP;
        return playerWasm.ccall('wp_playerlib_wasm_is_started', 'boolean', ['number'], [this.playerRef], {
            async: true,
        });
    }

    async destroy() {
        if (isNumber(this.playerRef)) {
            const playerWasm = await this.playerWasmP;
            const audioCtx = await this.audioCtx();
            await playerWasm.ccall('wp_playerlib_wasm_destroy', 'void', ['number'], [this.playerRef], { async: true });
            await audioCtx.close(); // for some reason emscripten's emscripten_destroy_audio_context only suspends, so we need to close to not leak resources
        }
    }
}
