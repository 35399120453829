import { difference, isEqual, isObject, uniqBy } from 'lodash';
import {
    ContentSwitch,
    isScheduledGenerativePathScore,
    LayerNumber,
    PathScoreEmotion,
    ScheduledPathScore,
    ScheduledWavepath,
} from 'wavepaths-shared/core';
import { selectStagePresetMix } from 'wavepaths-shared/scoreLibrary';
import { LayerInformation } from 'wavepaths-shared/types/content/core';

import useScoreLibrary, { ScoreLibrary } from '@/hooks/useScoreLibrary';

import { useAuthContext } from '../../../auth';
import { useAvailableLayers } from '../../../pages/inSession/InstrumentRefresh/useAvailableLayers';

type InstrumentLayerGroup = {
    id: string;
    layerNumbers: LayerNumber[];
};

type InstrumentationUnspecified = {
    type: 'unspecified';
};

type InstrumentationSpecified = {
    type: 'specified';
    layers: LayerInformation[];
};

type Instrumentation = InstrumentationUnspecified | InstrumentationSpecified;

type WaveInstrumentation = {
    group: InstrumentLayerGroup;
    instrumentation: Instrumentation;
}[];

type InstrumentId =
    | 'bass'
    | 'lowChords'
    | 'lowMelody'
    | 'highChords'
    | 'highMelody'
    | 'emotiveChords'
    | 'emotiveMelody'
    | 'deepen'
    | 'track';
const LAYER_INSTRUMENT_GROUPS: InstrumentLayerGroup[] = [
    { id: 'bass', layerNumbers: [1] },
    { id: 'lowChords', layerNumbers: [4] },
    { id: 'lowMelody', layerNumbers: [2] },
    { id: 'highChords', layerNumbers: [5] },
    { id: 'highMelody', layerNumbers: [3] },
    { id: 'emotiveChords', layerNumbers: [12] },
    { id: 'emotiveMelody', layerNumbers: [11] },
    { id: 'deepen', layerNumbers: [8, 9, 10, 16] },
    { id: 'track', layerNumbers: [13] },
] as const;

export function useWaveInstruments(wavepath: ScheduledWavepath) {
    const { firebaseUser } = useAuthContext();
    const scoreLibrary = useScoreLibrary(firebaseUser);
    const emotion = wavepath.pathScore.emotion;
    const ceas = emotion ? (isObject(emotion) ? [emotion.from, emotion.to] : [emotion]) : [];
    const availableLayers = useAvailableLayers({ ceas, fbUser: firebaseUser });
    const activeInstrumentGroups = getActiveInstrumentGroups(wavepath, scoreLibrary);
    const pinnedLayers = getPinnedLayers(wavepath.pathScore, availableLayers.layers);
    const waveInstrumentation = buildWaveInstrumentation(activeInstrumentGroups, pinnedLayers);

    const applyInstrumentation = (instrumentId: InstrumentId, instrumentation: Instrumentation): ScheduledWavepath => {
        const previousInstrumentation = waveInstrumentation.find((i) => i.group.id === instrumentId);
        const layersToPin = instrumentation.type === 'specified' ? instrumentation.layers : [];
        const layerIdsToPin = layersToPin.map((l) => l.id);
        const layerIdsToUnpin =
            previousInstrumentation?.instrumentation.type === 'specified'
                ? previousInstrumentation.instrumentation.layers.map((l) => l.id)
                : [];
        const collectionNamesToUnpin = layersToPin.map((l) => l.collection.name);

        let isFirstContentSwitch = true;
        const modifyContentSwitch = (contentSwitch: ContentSwitch | undefined) => {
            if (contentSwitch) {
                contentSwitch = {
                    ...contentSwitch,
                    layerIds: difference(contentSwitch.layerIds, layerIdsToUnpin),
                    collectionNames: difference(contentSwitch.collectionNames, collectionNamesToUnpin),
                };
                if (isFirstContentSwitch) {
                    contentSwitch = {
                        ...contentSwitch,
                        layerIds: [...(contentSwitch?.layerIds ?? []), ...layerIdsToPin],
                    };
                    isFirstContentSwitch = false;
                }
            }
            return contentSwitch;
        };

        return {
            ...wavepath,
            pathScore: {
                ...wavepath.pathScore,
                stages: wavepath.pathScore.stages.map((stage) => ({
                    ...stage,
                    contentSwitch: modifyContentSwitch(stage.contentSwitch),
                })),
            },
        };
    };

    return { waveInstrumentation, applyInstrumentation };
}

export function useAvailableInstruments(emotion: PathScoreEmotion | undefined, instrumentId: InstrumentId) {
    const { firebaseUser } = useAuthContext();
    const scoreLibrary = useScoreLibrary(firebaseUser);
    const ceas = emotion ? (isObject(emotion) ? [emotion.from, emotion.to] : [emotion]) : [];
    const availableLayers = useAvailableLayers({ ceas, fbUser: firebaseUser });
    const instrumentLayerGroup = LAYER_INSTRUMENT_GROUPS.find((group) => group.id === instrumentId);
    if (!instrumentLayerGroup) {
        throw new Error(`Instrument ${instrumentId} not found`);
    }

    const layerNumberFilteredLayers = availableLayers.layers.filter((layer) =>
        instrumentLayerGroup.layerNumbers.includes(layer.layerNumber as LayerNumber),
    );
    const functionFilteredLayers = filterAvailableLayersByFunction(emotion, scoreLibrary, layerNumberFilteredLayers);
    const groupedFilteredLayers = groupLayersByInclusionCriterion(functionFilteredLayers);
    return layersToInstrumentations(groupedFilteredLayers, instrumentLayerGroup);
}

function filterAvailableLayersByFunction(
    emotion: PathScoreEmotion | undefined,
    scoreLibrary: ScoreLibrary,
    layerNumberFilteredLayers: LayerInformation[],
) {
    if (isObject(emotion)) {
        // Bridge waves: A roundabout way of getting collections by finding path scores that have this emotionality
        // and seeing what collections they have pinned. This should really be in the layer database directly,
        // but we don't have it currently.
        if (!scoreLibrary.loading) {
            const pathScoresForEmotionality = scoreLibrary.pathScores
                .filter(isScheduledGenerativePathScore)
                .filter((ps) => isEqual(ps.emotion, emotion));
            const allPinnedLayers = pathScoresForEmotionality.flatMap((ps) =>
                getPinnedLayers(ps, layerNumberFilteredLayers),
            );
            return uniqBy(allPinnedLayers, (l) => l.id);
        } else {
            return [];
        }
    } else {
        return layerNumberFilteredLayers;
    }
}

function groupLayersByInclusionCriterion(layers: LayerInformation[]) {
    const INCLUSIVE_CRITERION_TAG_NAME = 'Marker';
    const INCLUSIVE_CRITERION_TAG_VALUE_PREFIX = 'inclusive-';
    const inclusionGroups = new Map<string, LayerInformation[]>();
    for (const layer of layers) {
        const markerTag = layer.tags[INCLUSIVE_CRITERION_TAG_NAME];
        if (typeof markerTag === 'string' && markerTag.startsWith(INCLUSIVE_CRITERION_TAG_VALUE_PREFIX)) {
            if (!inclusionGroups.has(markerTag)) {
                inclusionGroups.set(markerTag, []);
            }
            inclusionGroups.get(markerTag)?.push(layer);
        } else {
            inclusionGroups.set(`single-${layer.id}`, [layer]);
        }
    }
    return Array.from(inclusionGroups.values());
}

function layersToInstrumentations(groups: LayerInformation[][], instrumentLayerGroup: InstrumentLayerGroup) {
    const result: InstrumentationSpecified[] = [];
    for (const layerGroup of groups) {
        const layerNumbersInGroup = new Set(layerGroup.map((layer) => layer.layerNumber));
        const hasAllRequiredLayers = instrumentLayerGroup.layerNumbers.every((layerNumber) =>
            layerNumbersInGroup.has(layerNumber as LayerNumber),
        );
        if (hasAllRequiredLayers) {
            result.push({
                type: 'specified',
                layers: layerGroup,
            });
        } else {
            const missingLayerNumbers = instrumentLayerGroup.layerNumbers.filter(
                (layerNumber) => !layerNumbersInGroup.has(layerNumber as LayerNumber),
            );
            console.warn(
                `Layer group missing required layer numbers: ${missingLayerNumbers.join(
                    ', ',
                )} for group ${layerGroup} on instrument ${instrumentLayerGroup.id}`,
            );
        }
    }
    return result;
}

function getActiveInstrumentGroups(wavepath: ScheduledWavepath, scoreLibrary: ScoreLibrary) {
    if (scoreLibrary.loading) {
        return [];
    }
    const activeLayers = new Set<LayerNumber>();
    for (const stage of wavepath.pathScore.stages) {
        const preset = selectStagePresetMix(stage.preset, stage.stage, scoreLibrary.presetScores, []);
        for (const layerSettings of preset.stage.settings.layers) {
            if (layerSettings.vol > 0) {
                activeLayers.add(layerSettings.layer);
            }
        }
    }
    const activeInstrumentGroups = LAYER_INSTRUMENT_GROUPS.filter((group) =>
        group.layerNumbers.some((layer) => activeLayers.has(layer)),
    );
    return activeInstrumentGroups;
}

function getPinnedLayers(pathScore: ScheduledPathScore, availableLayers: LayerInformation[]) {
    const layers = new Set<LayerInformation>();
    for (const stage of pathScore.stages) {
        if (stage.contentSwitch) {
            for (const layerId of stage.contentSwitch.layerIds ?? []) {
                const layer = availableLayers.find((l) => l.id === layerId);
                if (layer) {
                    layers.add(layer);
                } else {
                    console.warn(`Pinned layer ${layerId} not found in available layers`);
                }
            }
            for (const collectionName of stage.contentSwitch.collectionNames ?? []) {
                const collectionLayers = availableLayers.filter((l) => l.collection.name === collectionName);
                if (collectionLayers.length > 0) {
                    for (const layer of collectionLayers) {
                        layers.add(layer);
                    }
                } else {
                    console.warn(`Layers for pinned collection ${collectionName} not found in available layers`);
                }
            }
        }
    }
    return Array.from(layers);
}

function buildWaveInstrumentation(activeInstrumentGroups: InstrumentLayerGroup[], pinnedLayers: LayerInformation[]) {
    const waveInstrumentation: WaveInstrumentation = [];
    for (const instrumentGroup of activeInstrumentGroups) {
        const groupPinnedLayers = pinnedLayers.filter((l) => instrumentGroup.layerNumbers.includes(l.layerNumber));
        if (groupPinnedLayers.length > 0) {
            waveInstrumentation.push({
                group: instrumentGroup,
                instrumentation: {
                    type: 'specified',
                    layers: groupPinnedLayers,
                },
            });
        } else {
            waveInstrumentation.push({
                group: instrumentGroup,
                instrumentation: { type: 'unspecified' },
            });
        }
    }
    return waveInstrumentation;
}
