import firebase from 'firebase';
import { isEmpty } from 'lodash';
import {
    clientsFreudApi,
    CoreEmotionalAtmosphere,
    GetBonnySubscriptionResponse,
    UserRoles,
} from 'wavepaths-shared/core';
import { LayerInformation } from 'wavepaths-shared/types/content/core';

import configs from '../../configs';
import axios from '../util/axios';
import { GetMyDetailsResponse } from './types';

export const FREUD_BASE_URL = configs.freud.BASE_URL;

export const signUp = async (
    fbUser: firebase.User,
    {
        name,
        utmTags,
        retries = 2,
        roles,
        groups,
        client_id,
    }: {
        name: string;
        retries?: number;
        utmTags?: { [key: string]: string };
        roles?: UserRoles[];
        groups?: string[];
        client_id?: string;
    },
): Promise<void> => {
    const url = configs.cloudFunctions.URL + '/signUp';
    try {
        await axios(url, {
            method: 'POST',
            headers: { Authorization: `idToken ${await fbUser.getIdToken()}` },
            data: {
                name,
                ...(!isEmpty(utmTags) && { utmTags }),
                roles,
                groups,
                client_id,
            },
        });
    } catch (error: any) {
        const status = error.status || (error.response && error.response.status);
        switch (status) {
            default:
                if (retries > 0) return signUp(fbUser, { name, utmTags, retries: retries - 1, roles, groups });
                throw new Error('UnknownSignUpError');
        }
    }
};

export const saveTherapistLeadInfo = async (
    fbUser: firebase.User,
    //TODO: Re-use prisma type
    therapistInfo: {
        modality: string;
        medicine?: string;
        userType: string;
        wantsIndividualTraining: boolean;
        wantsTailoredOnboarding: boolean;
        sessionCount: string;
    },
) => {
    const url = FREUD_BASE_URL + '/api/v1/therapists/' + fbUser.uid;
    try {
        await axios(url, {
            method: 'POST',
            headers: { Authorization: `idToken ${await fbUser.getIdToken()}` },
            data: {
                ...therapistInfo,
                user_id: fbUser.uid,
            },
        });
    } catch (e) {
        throw e;
    }
};

//TODO API endpoint return typing

export const getClients = async (
    fbUser: firebase.User,
    query: string,
    client_id?: string,
): Promise<clientsFreudApi[]> => {
    const url =
        FREUD_BASE_URL + `/clients?query=${encodeURIComponent(query)}&client_id=${encodeURIComponent(client_id ?? '')}`;
    try {
        const response = await axios(url, {
            method: 'GET',
            headers: { Authorization: `idToken ${await fbUser.getIdToken()}` },
        });
        if (response.status != 200) {
            console.debug(response);
            throw new Error('Error fetching');
        }

        return response.data;
    } catch (e) {
        console.debug('Error', e);
        throw e;
    }
};

export const getAvailableLayers = async ({
    fbUser,
    ceas = [],
}: {
    fbUser: firebase.User;
    ceas?: CoreEmotionalAtmosphere[];
}): Promise<LayerInformation[]> => {
    const params = new URLSearchParams();
    ceas.forEach((cea) => params.append('cea', cea));
    const url = FREUD_BASE_URL + `/instruments/available?${params.toString()}`;
    try {
        const response = await axios(url, {
            method: 'GET',
            headers: { Authorization: `idToken ${await fbUser.getIdToken()}` },
        });
        if (response.status != 200) {
            console.debug(response);
            throw new Error('Error fetching');
        }

        return response.data;
    } catch (e) {
        console.debug('Error', e);
        throw e;
    }
};

export const getLayerPreview = async (fbUser: firebase.User, layerIds: number[]): Promise<string[]> => {
    const params = new URLSearchParams();
    layerIds.forEach((id) => params.append('layer', id.toString()));
    const url = FREUD_BASE_URL + `/instruments/preview?${params.toString()}`;
    try {
        const response = await axios(url, {
            method: 'GET',
            headers: { Authorization: `idToken ${await fbUser.getIdToken()}` },
        });
        if (response.status != 200) {
            console.debug(response);
            throw new Error('Error fetching');
        }

        return response.data;
    } catch (e) {
        console.debug('Error', e);
        throw e;
    }
};

export const upsertClient = async (
    fbUser: firebase.User,
    //TODO: Re-use API entrypoint type
    payload: Pick<clientsFreudApi, 'firstname' | 'lastname' | 'notes' | 'instrument_blacklist'> &
        Partial<Pick<clientsFreudApi, 'client_id'>>,
) => {
    const url = FREUD_BASE_URL + '/clients/upsert';
    try {
        const response = await axios(url, {
            method: 'POST',
            headers: { Authorization: `idToken ${await fbUser.getIdToken()}` },
            data: payload,
        });
        if (response.status != 200) {
            console.debug(response);
            throw new Error('Error adding client');
        }

        //console.debug(response);
        //TODO API endpoint return typing
        return response.data as clientsFreudApi;
    } catch (e) {
        throw e;
    }
};

export const deleteClient = async (fbUser: firebase.User, clientId: string) => {
    const url = FREUD_BASE_URL + '/clients/delete';
    try {
        const response = await axios(url, {
            method: 'POST',
            headers: { Authorization: `idToken ${await fbUser.getIdToken()}` },
            data: { clientId },
        });
        if (response.status != 200) {
            console.debug(response);
            throw new Error('Error deleting client');
        }

        return response.data as clientsFreudApi;
    } catch (e) {
        throw e;
    }
};

export const correlateAuthUserWithClient = async (
    fbUser: firebase.User,
    payload: Pick<clientsFreudApi, 'client_id'>,
) => {
    const url = FREUD_BASE_URL + '/clients/correlateAuthUser';
    try {
        const response = await axios(url, {
            method: 'POST',
            headers: { Authorization: `idToken ${await fbUser.getIdToken()}` },
            data: payload,
        });
        if (response.status != 200) {
            console.debug(response);
            throw new Error('Error correlating client');
        }

        //console.debug(response);
        //TODO API endpoint return typing
        //return response.data as clientsFreudApi;
    } catch (e) {
        throw e;
    }
};

export const getMySubscription = async (fbUser: firebase.User): Promise<GetBonnySubscriptionResponse> => {
    const url = configs.cloudFunctions.URL + '/bonnyApi/my/subscription';
    const response = await axios(url, {
        method: 'GET',
        headers: { Authorization: `idToken ${await fbUser.getIdToken()}` },
    });

    if (response.status === 200) return response.data;
    else throw new Error('Failed to fetch subscription');
};

export const getMyDetails = async (fbUser: firebase.User): Promise<GetMyDetailsResponse> => {
    const url = configs.cloudFunctions.URL + '/bonnyApi/my/details';
    const response = await axios(url, {
        method: 'GET',
        headers: { Authorization: `idToken ${await fbUser.getIdToken()}` },
    });

    if (response.status >= 200 && response.status < 300) return response.data;
    else throw new Error('Failed to fetch details');
};

export const setMyEmail = async (fbUser: firebase.User, email: string): Promise<GetMyDetailsResponse> => {
    const url = configs.cloudFunctions.URL + '/bonnyApi/my/email';
    const response = await axios(url, {
        method: 'PUT',
        headers: { Authorization: `idToken ${await fbUser.getIdToken()}` },
        data: {
            email,
        },
    });

    if (response.status >= 200 && response.status < 300) return response.data;
    else throw new Error('Failed to update email');
};

export const getUserSubscription = async (userId: string): Promise<GetBonnySubscriptionResponse> => {
    const url = configs.cloudFunctions.URL + '/bonnyApi/user/subscription';
    try {
        const response = await axios({
            url,
            method: 'GET',
            params: {
                userId,
            },
        });

        if (response.status === 200) {
            return response.data;
        } else {
            throw new Error(`Failed to fetch subscriptions: ${response.statusText}`);
        }
    } catch (error) {
        console.error('Error fetching user subscriptions:', error);
        throw new Error('Failed to fetch user subscriptions');
    }
};
