import styled from '@emotion/styled';
import { AnimatePresence, motion } from 'framer-motion';
import React from 'react';
import { SessionRenderType } from 'wavepaths-shared/core';

import Subheading from '@/components/Subheading';

import CheckboxCheckedIcon from '../../../../images/icons/icon-checkbox-checked.svg';
import CheckboxUncheckedIcon from '../../../../images/icons/icon-checkbox-unchecked.svg';
import SpeakerIcon from '../../../../images/icons/icon-speaker.svg';
import { PlayerChoice, WhereToPlayMusic } from '../PlayerChoiceContext';
import PlayOnLocalDevice from './PlayOnLocalDevice';
import PlayOnRemoteDevice from './PlayOnRemoteDevice';

const Container = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 16px;
`;

const CheckboxGroup = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 8px;
`;

const Checkbox = styled.div<{ isChecked: boolean }>`
    width: 100%;
    display: flex;
    flex-direction: column;
    box-shadow: ${({ isChecked }) => (isChecked ? 'inset 0 0 0 2px #103da8' : 'inset 0 0 0 1px #EAECF0')};
    border-radius: 12px;
`;

const CheckboxHeader = styled.div<{ isChecked: boolean }>`
    padding: 16px 20px 16px 16px;
    font-size: 16px;
    font-weight: 600;
    color: ${({ isChecked }) => (isChecked ? '#103da8' : '#2b2d3f')};
    border: ${({ isChecked }) => (isChecked ? '2px solid #103da8' : '2px solid transparent')};
    border-bottom: none;
    border-radius: 12px 12px 0 0;
    background-color: ${({ isChecked }) => (isChecked ? '#eef1f6' : 'transparent')};
    background-image: url(${({ isChecked }) => (isChecked ? CheckboxCheckedIcon : CheckboxUncheckedIcon)});
    background-repeat: no-repeat;
    background-position: right 16px center;
    background-size: 20px;
    cursor: pointer;
    user-select: none;
`;

const CheckboxBody = styled(motion.div)`
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 8px;
    /* background: #fff; */
    border-radius: 0 0 12px 12px;
`;

interface PlaybackOptionsProps {
    broadcastIdentifier: string;
    whereToPlayMusic: WhereToPlayMusic | undefined;
    playerChoice: PlayerChoice;
    browserPlayerOutputDevice: string | undefined;
    isPlayerAppSupported: boolean;
    isWebPlayerSupported: boolean;
    freePaidPathPartForPlayerAppLink?: string;
    onSetWhereToPlayMusic: (whereToPlayMusic: WhereToPlayMusic) => void;
    onSetPlayerChoice: (playerChoice: PlayerChoice) => void;
    onSetBrowserPlayerOutputDevice: (deviceId: string) => void;
    sessionRenderType?: SessionRenderType;
}

export default function PlaybackOptions({
    broadcastIdentifier,
    whereToPlayMusic,
    playerChoice,
    browserPlayerOutputDevice,
    isPlayerAppSupported,
    isWebPlayerSupported,
    freePaidPathPartForPlayerAppLink = 'paid',
    onSetWhereToPlayMusic,
    onSetPlayerChoice,
    onSetBrowserPlayerOutputDevice,
    sessionRenderType,
}: PlaybackOptionsProps): React.ReactElement {
    const isThisDeviceChecked = whereToPlayMusic === 'thisDevice' || whereToPlayMusic === 'thisAndRemote';
    const isRemoteChecked = whereToPlayMusic === 'remoteOnly' || whereToPlayMusic === 'thisAndRemote';

    const handlePlaybackCheckboxClick = (device: 'thisDevice' | 'remoteOnly') => {
        let willThisDeviceBeChecked = isThisDeviceChecked;
        let willRemoteBeChecked = isRemoteChecked;
        if (device === 'thisDevice') {
            if (isThisDeviceChecked && !isRemoteChecked) {
                // Prevent unchecking if it's the only one checked
                return;
            }
            willThisDeviceBeChecked = !isThisDeviceChecked;
        } else if (device === 'remoteOnly') {
            if (isRemoteChecked && !isThisDeviceChecked) {
                // Prevent unchecking if it's the only one checked
                return;
            }
            willRemoteBeChecked = !isRemoteChecked;
        }

        if (willThisDeviceBeChecked && willRemoteBeChecked) {
            onSetWhereToPlayMusic('thisAndRemote');
        } else if (willThisDeviceBeChecked) {
            onSetWhereToPlayMusic('thisDevice');
        } else if (willRemoteBeChecked) {
            onSetWhereToPlayMusic('remoteOnly');
        }
    };

    return (
        <Container>
            <Subheading
                title={'Where should the music play?'}
                description={'Select one or more options.'}
                icon={SpeakerIcon}
            />
            <CheckboxGroup>
                <Checkbox isChecked={isThisDeviceChecked}>
                    <CheckboxHeader
                        onClick={() => handlePlaybackCheckboxClick('thisDevice')}
                        isChecked={isThisDeviceChecked}
                    >
                        Play music on this device
                    </CheckboxHeader>
                    <AnimatePresence>
                        {isThisDeviceChecked && (
                            <CheckboxBody
                                initial={{ opacity: 0 }}
                                animate={{ opacity: 1 }}
                                exit={{ opacity: 0 }}
                                transition={{ duration: 0.15 }}
                            >
                                <PlayOnLocalDevice
                                    broadcastIdentifier={broadcastIdentifier}
                                    playerChoice={playerChoice}
                                    browserPlayerOutputDevice={browserPlayerOutputDevice}
                                    onSetBrowserPlayerOutputDevice={onSetBrowserPlayerOutputDevice}
                                    isPlayerAppSupported={isPlayerAppSupported}
                                    isWebPlayerSupported={isWebPlayerSupported}
                                    freePaidPathPartForPlayerAppLink={freePaidPathPartForPlayerAppLink}
                                    onSetPlayerChoice={onSetPlayerChoice}
                                    sessionRenderType={sessionRenderType}
                                />
                            </CheckboxBody>
                        )}
                    </AnimatePresence>
                </Checkbox>
                <Checkbox isChecked={isRemoteChecked}>
                    <CheckboxHeader
                        onClick={() => handlePlaybackCheckboxClick('remoteOnly')}
                        isChecked={isRemoteChecked}
                    >
                        Play music on another device
                    </CheckboxHeader>
                    <AnimatePresence>
                        {isRemoteChecked && (
                            <CheckboxBody
                                initial={{ opacity: 0, height: 0 }}
                                animate={{ opacity: 1, height: 'auto' }}
                                exit={{ opacity: 0, height: 0 }}
                                transition={{ duration: 0.15 }}
                            >
                                <PlayOnRemoteDevice
                                    broadcastIdentifier={broadcastIdentifier}
                                    isPlayerAppSupported={isPlayerAppSupported}
                                    freePaidPathPartForPlayerAppLink={freePaidPathPartForPlayerAppLink}
                                />
                            </CheckboxBody>
                        )}
                    </AnimatePresence>
                </Checkbox>
            </CheckboxGroup>
        </Container>
    );
}
