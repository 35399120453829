import firebase from 'firebase';
import useSWR from 'swr';
import { CoreEmotionalAtmosphere } from 'wavepaths-shared/core';

import { getAvailableLayers } from '../../../common/api/bonnyApi';

export function useAvailableLayers({
    ceas = [],
    fbUser,
}: {
    ceas?: CoreEmotionalAtmosphere[];
    fbUser?: firebase.User;
}) {
    const { data, isValidating } = useSWR(
        fbUser ? ['getLayersByCea', ceas.join(',')] : null,
        fbUser ? () => getAvailableLayers({ fbUser, ceas }) : null,
        {
            compare: (a, b) => {
                return JSON.stringify(a) == JSON.stringify(b);
            },
            revalidateOnFocus: false,
        },
    );

    const layers = data ?? [];

    return {
        layers,
        isLoading: isValidating,
    };
}
