import styled from '@emotion/styled';
import React from 'react';

import { formatDate } from '../../dateUtilsV2';
import { NewsArticleRichEditor } from './NewsArticleRichEditor';
import { NewsArticle } from './useNews';

export enum NewsArticleVariant {
    Default = 'default',
    Compact = 'compact',
}

const Container = styled.div<{ isViewed?: boolean; variant?: NewsArticleVariant }>`
    position: relative;
    width: 100%;
    ${({ variant, isViewed }) => {
        if (variant === NewsArticleVariant.Compact) {
            return `
                background-color: ${!isViewed ? 'var(--color-background-highlight)' : 'var(--color-background)'};
                padding: 0px 8px;
                border-top: 1px solid #e7e7e7;
            `;
        } else {
            return `
                background-color: transparent;
            `;
        }
    }}
`;

const Header = styled.div<{ variant?: NewsArticleVariant }>`
    ${({ variant }) =>
        variant === NewsArticleVariant.Default ? 'padding: 16px 0 var(--global-spacing) 0;' : 'padding: 8px 0;'};
    display: flex;
    flex-direction: column;
    cursor: pointer;
    ${({ variant }) =>
        variant === NewsArticleVariant.Default ? 'border-top: 4px solid var(--color-background-highlight);' : ''}
`;

const Tag = styled.p<{ variant?: NewsArticleVariant }>`
    font-size: ${({ variant }) => (variant === NewsArticleVariant.Compact ? '8px' : '10px')};
    font-weight: 600;
    letter-spacing: 1px;
    text-transform: uppercase;
    margin: 0 0 8px 0;
`;

const Date = styled.p<{ variant?: NewsArticleVariant }>`
    font-size: ${({ variant }) =>
        variant === NewsArticleVariant.Compact ? 'var(--font-size-xsmall)' : 'var(--font-size-small)'};
    margin: 0;
`;

const Title = styled.h3<{ variant?: NewsArticleVariant }>`
    font-size: ${({ variant }) => (variant === NewsArticleVariant.Compact ? '16px' : '18px')};
    margin: 0;
`;

type NewsArticleProps = React.PropsWithChildren<{
    article: NewsArticle;
    isExpanded?: boolean;
    variant?: NewsArticleVariant; // Используем enum для variant
    onClick?: () => void;
}>;

export function NewsArticle({
    article,
    isExpanded = false,
    variant = NewsArticleVariant.Default,
    onClick,
    children,
}: NewsArticleProps) {
    const date = article.date ? formatDate(article.date) : undefined;
    return (
        <Container isViewed={article.isViewed} variant={variant}>
            <Header onClick={onClick}>
                <Tag variant={variant}>{article.tag}</Tag>
                <Title variant={variant}>{article.shortDescription}</Title>
                {date && <Date variant={variant}>{date}</Date>}
            </Header>
            {isExpanded && article.description && (
                <NewsArticleRichEditor value={article.description} isEditable={false} />
            )}
            {children}
        </Container>
    );
}
