import { useState } from 'react';
import { LayerInformation } from 'wavepaths-shared/types/content/core';

import { useAuthContext } from '../../auth';
import configs from '../../configs';
import { AudioPlayerStream } from '../../pages/inSession/AudioPlayerProvider';
import { LONG_TIME_FROM_NOW, usePlayerLibAudioPlayer } from '../../pages/inSession/usePlayerLibAudioPlayer';
import { getLayerPreview } from '../api/bonnyApi';

type InstrumentPreviewStateNotPlaying = { state: 'notPlaying' };
type InstrumentPreviewStateStarting = { state: 'starting'; instrument: LayerInformation[] };
type InstrumentPreviewStatePlaying = { state: 'playing'; instrument: LayerInformation[]; streams: AudioPlayerStream[] };
type InstrumentPreviewStateStopping = {
    state: 'stopping';
    instrument: LayerInformation[];
    streams: AudioPlayerStream[];
};
type InstrumentPreviewState =
    | InstrumentPreviewStateNotPlaying
    | InstrumentPreviewStateStarting
    | InstrumentPreviewStateStopping
    | InstrumentPreviewStatePlaying;

export function useInstrumentPreview() {
    const { firebaseUser } = useAuthContext();
    const [state, setState] = useState<InstrumentPreviewState>({ state: 'notPlaying' });
    const player = usePlayerLibAudioPlayer({
        playDemoVO: false,
        streams: state.state === 'playing' || state.state === 'stopping' ? state.streams : [],
        voiceOverStages: [],
        sessionDuration: 10 * 60,
    });

    const start = async (instrument: LayerInformation[]) => {
        setState({ state: 'starting', instrument });
        await player.actions.play();
        if (!firebaseUser) {
            throw new Error('No user');
        }
        const audioFiles = await getLayerPreview(
            firebaseUser,
            instrument.map((i) => i.id),
        );
        const now = await player.getCurrentTimeSecs();
        const streams: AudioPlayerStream[] = audioFiles.map((path) => ({
            id: path,
            url: `${configs.freud.SAMPLE_PREVIEW_BASE_URL}${path}`,
            fromTime: now * 1000 + 200,
            toTime: LONG_TIME_FROM_NOW,
            loopContent: true,
        }));
        setState((state) =>
            state.state === 'starting' && state.instrument === instrument
                ? { state: 'playing', instrument, streams }
                : state,
        );
    };

    const stop = async () => {
        const stateNow = state;
        if (stateNow.state !== 'notPlaying') {
            setState({
                state: 'stopping',
                instrument: stateNow.instrument,
                streams: stateNow.state === 'starting' ? [] : stateNow.streams,
            });
            await player.actions.pause({ fadeMs: 100, reason: 'user' });
            setTimeout(() => {
                setState((state) =>
                    state.state === 'stopping' && state.instrument === stateNow.instrument
                        ? { state: 'notPlaying' }
                        : state,
                );
            }, 100);
        }
    };

    return {
        state,
        start,
        stop,
    };
}
