import firebase from 'firebase';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { SessionHeader, SessionRenderType } from 'wavepaths-shared/core';

import { Dialog } from '@/component-library';

import * as api from '../../common/api/sessionApi';
import { formatDateTime } from '../../dateUtilsV2';
import UserEvents, { CopiedSessionTab } from '../../UserEvents';
import RemoteOptionsDialog from '../inSession/deviceOptions/RemoteOptionsDialog';
import { renderSessionClients, renderSessionName } from './helpers';
import SessionCard, { ISessionAction } from './SessionCard';

interface IProps {
    session: SessionHeader;
    isAdmin: boolean;
    setCopiedLinkId: (copiedLinkId?: string) => void;
    copiedLinkId?: string;
    firebaseUser: firebase.User;
}

const ScheduledSessionCard = ({ session, isAdmin, firebaseUser }: IProps): JSX.Element => {
    const history = useHistory();
    const [confirmStartOpen, setConfirmStartOpen] = useState(false);
    const [confirmCancelOpen, setConfirmCancelOpen] = useState(false);

    const sessionName = renderSessionName(session);
    const clientNames = renderSessionClients(session);

    const onCancelSessionConfirmed = () => {
        firebaseUser && api.deleteSession(session.id, 0, firebaseUser);
        setConfirmCancelOpen(false);
    };

    const onCloseConfirmCancel = () => {
        setConfirmCancelOpen(false);
    };

    const handleDelete = () => {
        setConfirmCancelOpen(true);
    };

    const handleMakeCopy = async () => {
        UserEvents.copiedSession(CopiedSessionTab.SCHEDULED);
        window.location.href = `/session/copy/${session.id}`;
    };

    const startSessionEarly = () => {
        api.startSessionEarly(session.id, firebaseUser).then(() => history.push(`/sessions/?tabIndex=1`));
    };
    const handleStartNow = () => {
        setConfirmStartOpen(true);
    };

    const onClickLog = () => {
        history.push(`/logs/${session.id}`);
    };

    const onCloseConfirmStart = () => {
        setConfirmStartOpen(false);
    };

    const onConfirmStart = () => {
        setConfirmStartOpen(false);
        startSessionEarly();
    };

    const [showShareSessionDialog, setShowShareSessionDialog] = useState(false);

    const userInfoText = session?.user?.name;

    // TODO: it can be a string that says "undefined" - not ideal
    // TODO: modality from where?
    // const subtitle = getSubtitle(`${(session.score as any)?.modality}`, session.variableInputs.dosage);

    const actions = [
        // TODO: If session is not started rendering the player breaks
        ...(session.renderType !== SessionRenderType.PRE_RENDERED
            ? [{ description: 'Streaming Link', action: () => setShowShareSessionDialog(true) }]
            : []),
        { description: 'Make A Copy', action: handleMakeCopy },
        { description: 'Delete', action: handleDelete },
        ...(isAdmin ? [{ description: 'Log', action: onClickLog }] : []),
    ] as ISessionAction[];

    return (
        <React.Fragment key={session.id}>
            <RemoteOptionsDialog
                sessionId={session.id}
                broadcastIdentifier={session.broadcastIdentifier}
                isPlayerAppSupported={session.renderType !== SessionRenderType.REAL_TIME_DEPRECATED}
                isOpen={showShareSessionDialog}
                onClose={() => setShowShareSessionDialog(false)}
            />
            <Dialog
                fullWidth={true}
                open={confirmCancelOpen}
                onClose={onCloseConfirmCancel}
                onConfirm={onCancelSessionConfirmed}
                message={`Are you sure you want to delete the "${sessionName}" session?`}
                confirmText={'Delete Session'}
            />
            <Dialog
                fullWidth={true}
                open={confirmStartOpen}
                onClose={onCloseConfirmStart}
                onConfirm={onConfirmStart}
                message={`Are you sure you want to start "${sessionName}" now?`}
                confirmText={'Start Session'}
            />
            <SessionCard
                title={sessionName}
                subtitle={clientNames}
                userInfoText={isAdmin ? userInfoText : undefined}
                primaryActionLabel={
                    session.renderType !== SessionRenderType.PRE_RENDERED ? 'Start' : 'Generate & Start'
                }
                primaryAction={handleStartNow}
                actions={actions}
                info={[session.scheduledStart ? `Starts ${formatDateTime(session.scheduledStart)}` : 'Saved for later']}
            />
        </React.Fragment>
    );
};
export default ScheduledSessionCard;
