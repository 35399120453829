import styled from '@emotion/styled';
import React, { useContext, useState } from 'react';
import { useInterval } from 'react-use';
import { Session, SessionRenderType } from 'wavepaths-shared/core';
import { formatDurationMinutes, formatDurationSeconds } from 'wavepaths-shared/util/dateUtils';

import { EvaIcon } from '@/component-library';

import { AudioPlayerContext } from './AudioPlayerProvider';

const Container = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 4px;
    font-family: inherit;
    font-size: 16px;
    font-weight: 600;
    color: #2b2d3f;
`;

interface SessionBufferSizeProps {
    session: Session;
}

export const SessionBufferSize: React.FC<SessionBufferSizeProps> = ({ session }: SessionBufferSizeProps) => {
    const audioPlayerContext = useContext(AudioPlayerContext);
    const [currentBufferSizeSecs, setCurrentBufferSizeSecs] = useState<number | undefined>(undefined);
    useInterval(async () => {
        setCurrentBufferSizeSecs(await audioPlayerContext?.getCurrentBufferSizeSecs());
    }, 1000);
    const bufferFormatted =
        currentBufferSizeSecs !== undefined
            ? currentBufferSizeSecs > 60
                ? formatDurationMinutes(currentBufferSizeSecs * 1000)
                : formatDurationSeconds(currentBufferSizeSecs * 1000)
            : undefined;

    return session.renderType === SessionRenderType.PREDICTIVE_COMPOSED &&
        bufferFormatted !== undefined &&
        currentBufferSizeSecs !== undefined ? (
        <Container>
            <EvaIcon
                name="wifi-outline"
                size={24}
                fill={currentBufferSizeSecs > 60 ? 'green' : currentBufferSizeSecs > 20 ? 'orange' : 'red'}
            />
            <span style={{ width: '100px' }}>{bufferFormatted}</span>
        </Container>
    ) : null;
};
