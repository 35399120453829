import styled from '@emotion/styled';
import { makeStyles } from '@material-ui/core';
import React, { useContext, useState } from 'react';
import { createPortal } from 'react-dom';
import { useInterval } from 'react-use';
import { CoreEmotionalAtmosphere, Session, Wavepath } from 'wavepaths-shared/core';
import { isWavepathActive } from 'wavepaths-shared/domain/wavepath';

import { getCEAColour } from '@/util/ceaColours';

import { AudioPlayerContext } from '../AudioPlayerProvider';

const useStyles = makeStyles({
    container: {
        position: 'fixed',
        left: 0,
        top: 0,
        width: '100vw',
        height: '100vh',
        pointerEvents: 'none',
    },
});

const MinimalDepthStateVisualizer = styled.div<{ colorHex?: string; colorTransitionTimeInSecs: number }>(
    ({ colorHex, colorTransitionTimeInSecs }) => ({
        width: '100%',
        height: '100%',
        backgroundColor: colorHex,
        opacity: 0.3,
        willChange: 'background-color',
        transition: `background-color ${colorTransitionTimeInSecs}s`,
    }),
);

interface DepthStateVisualizerProps {
    session: Session;
}
export const DepthStateVisualiser: React.FC<DepthStateVisualizerProps> = ({ session }) => {
    const styles = useStyles();

    const backgroundContainer = document.getElementById('backgroundcontainer');
    if (!backgroundContainer) {
        throw new Error('Document does not have a #backgroundcontainer element for depth state visualiser');
    }

    const audioPlayerContext = useContext(AudioPlayerContext);
    const [timeElapsedMs, setTimeElapsedMs] = useState(0);
    useInterval(async () => {
        const timeElapsedMs = await audioPlayerContext?.getCurrentTimeSecs();
        setTimeElapsedMs(timeElapsedMs ?? 0);
    }, 1000);
    const targetCea = getCeaAtTime(session.score.wavepaths, timeElapsedMs);
    const colorHex = targetCea ? getCEAColour(targetCea) : '#D3D3D3';
    const colorTransitionTimeInSecs = 30;

    return createPortal(
        <div className={styles.container}>
            <MinimalDepthStateVisualizer colorHex={colorHex} colorTransitionTimeInSecs={colorTransitionTimeInSecs} />
        </div>,
        backgroundContainer,
    );
};

const getCeaAtTime = (wavepaths: Wavepath[], elapsedTimeMs: number): CoreEmotionalAtmosphere | undefined => {
    // this should actually be calculated from the path score stages, not the top level "emotion"
    const activeWave = getCurrentWave(wavepaths, elapsedTimeMs);
    if (!activeWave) return;

    if ('emotion' in activeWave.pathScore) {
        if (typeof activeWave.pathScore.emotion === 'string') return activeWave.pathScore.emotion;
        if (!activeWave.pathScore.emotion) return;
        return activeWave.pathScore.emotion.from;
    }
};

const getCurrentWave = (wavepaths: Wavepath[], elapsedTimeMs: number): Wavepath | undefined => {
    return wavepaths.find(isWavepathActive(elapsedTimeMs));
};
