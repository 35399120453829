export enum Features {
    FREE_ACCESS = 'freeAccess',
    THERAPIST_NOTES = 'therapistNotes',
    LIVE_SESSION_RENDERING = 'realTimeAdaptation',
    UPLOAD_CUSTOM_SOUNDS = 'uploadCustomSounds',
    SAVE_SESSION_TEMPLATES = 'saveSessionTemplates',
    SESSION_DASHBOARD = 'sessionDashboard',
    WAVE_EDITOR = 'waveEditor',
    CLIENT_INTEGRATION = 'clientIntegration',
    BLOCKED_REMOTE_LISTEN = 'blockedRemoteListen',
    INSTRUMENT_CONTROL = 'instrumentControl',
}

const enabledFeatures: Features[] = [
    Features.FREE_ACCESS,

    // advanced tier features below
    Features.LIVE_SESSION_RENDERING,
    Features.UPLOAD_CUSTOM_SOUNDS,
    Features.SAVE_SESSION_TEMPLATES,
    Features.SESSION_DASHBOARD,
    Features.WAVE_EDITOR,
    Features.CLIENT_INTEGRATION,
    Features.BLOCKED_REMOTE_LISTEN,
    Features.INSTRUMENT_CONTROL,
];

export default enabledFeatures;
