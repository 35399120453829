import firebase from 'firebase/app';
import { useState } from 'react';
import {
    ClientVariablesMusicPreference,
    ScheduledWavepath,
    Session,
    SessionRenderType,
    SessionScoreDTO,
    SessionScoreSessionUse,
    SessionType,
    SessionVariables,
} from 'wavepaths-shared/core';

import { Features } from '@/features';

import { useAuthContext } from '../../auth';
import configs from '../../configs';
import { LONG_TIME_FROM_NOW, usePlayerLibAudioPlayer } from '../../pages/inSession/usePlayerLibAudioPlayer';
import * as sessionApi from '../api/sessionApi';

type StateNotCreated = { state: 'notCreated' };
type StateCreating = { state: 'creating' };
type StateCreated = { state: 'created'; session: Session };
type StateError = { state: 'error'; reason: string };
type State = StateNotCreated | StateCreating | StateCreated | StateError;

interface WavePreviewProps {
    wavepath: ScheduledWavepath;
}

export function useWavePreview({ wavepath }: WavePreviewProps) {
    const [state, setState] = useState<State>({ state: 'notCreated' });
    const { firebaseUser } = useAuthContext();

    async function render() {
        if (!firebaseUser) {
            setState({ state: 'error', reason: 'No user' });
            return;
        }
        if (!wavepath.plan) {
            setState({ state: 'error', reason: 'No plan in wavepath' });
            return;
        }
        const planDuration = wavepath.plan.toTime - wavepath.plan.fromTime;
        const wavepathWithPlanAtStartOfSession = { ...wavepath, plan: { fromTime: 0, toTime: planDuration } };

        setState({ state: 'creating' });

        try {
            const createdSession = await createSession(wavepathWithPlanAtStartOfSession, firebaseUser);
            setState({ state: 'created', session: createdSession });
        } catch (error) {
            setState({ state: 'error', reason: String(error) });
        }
    }

    return { state, render };
}

type PlayerStateCreated = { state: 'created' };
type PlayerStatePlaying = { state: 'playing' };
type PlayerState = PlayerStateCreated | PlayerStatePlaying;

interface WavePreviewPlayerProps {
    session?: Session;
}

export function useWavePreviewPlayer({ session }: WavePreviewPlayerProps) {
    const [playerState, setPlayerState] = useState<PlayerState>({ state: 'created' });
    const { isEnabled } = useAuthContext();
    const playDemoVO = !isEnabled(Features.FREE_ACCESS);

    const player = usePlayerLibAudioPlayer({
        playDemoVO,
        streams: session
            ? [
                  {
                      id: session.id,
                      url: `${configs.freud.STREAM_BASE}/${session.broadcastIdentifier}/${session.id}/stream.m3u8`,
                      fromTime: 0,
                      toTime: LONG_TIME_FROM_NOW,
                      loopContent: false,
                  },
              ]
            : [],
        broadcastElapsedTimeSecs: 0,
        sessionDuration: Number(session?.variableInputs.totalDuration ?? 0) * 1000 * 60,
        voiceOverStages: [],
    });

    const play = () => {
        if (!session) {
            return;
        }
        player.actions.play();
        setPlayerState({ state: 'playing' });
    };

    const pause = () => {
        if (!session) {
            return;
        }
        player.actions.pause({ fadeMs: 300, reason: 'user' });
        setPlayerState({ state: 'created' });
    };

    return { state: playerState, play, pause };
}

async function createSession(
    wavepath: ScheduledWavepath & { plan: { fromTime: number; toTime: number } },
    firebaseUser: firebase.User,
) {
    const durationMins = (wavepath.plan?.toTime - wavepath.plan?.fromTime) / 60000;

    const variableInputs: SessionVariables = {
        sessionUse: SessionScoreSessionUse.IN_PERSON,
        name: '',
        voiceover: 'none',
        Acousticness: ClientVariablesMusicPreference.MIXED,
        totalDuration: durationMins,
    };
    const attributeInputs: Partial<Session> = {
        unlisted: true,
        canClientStartEarly: false,
        scheduledStart: 1,
    };
    const sessionScore: SessionScoreDTO = {
        name: `Wave Preview ${wavepath.id}`,
        sessionTypes: [SessionType.WAVE_PREVIEW],
        variables: {
            inputs: [
                {
                    name: 'totalDuration',
                    type: 'number',
                    defaultValue: durationMins,
                },
                {
                    name: 'preludeDuration',
                    type: 'number',
                    defaultValue: 0,
                },
                {
                    name: 'postludeDuration',
                    type: 'number',
                    defaultValue: 0,
                },
            ],
        },
        showInMenu: true,
        wavepaths: [wavepath],
    };
    const createdSession = await sessionApi.startSession(
        SessionType.WAVE_PREVIEW,
        SessionRenderType.PRE_RENDERED,
        sessionScore,
        variableInputs,
        attributeInputs,
        [],
        firebaseUser,
        [],
        ['Approved'],
        false,
    );
    return createdSession;
}
