import { range } from 'lodash';
import { useEffect, useRef, useState } from 'react';
import { useLifecycles } from 'react-use';
import { VoiceoverStage } from 'wavepaths-shared/core';

import configs from '../../configs';
import { AudioPlayer, AudioPlayerProps, AudioPlayerStream } from './AudioPlayerProvider';
import type Hls from './hls.js.d';
import { PlayerLibInterface, PlayerLibStream, PlayerLibStreamPhase } from './PlayerLibInterface';
import { loadHls } from './useHLSAudioPlayer';

export const STREAM_FADE_OUT_TIME = 15000;
export const LONG_TIME_FROM_NOW = 365 * 24 * 60 * 60 * 1000;

export function usePlayerLibAudioPlayer({
    streams,
    broadcastElapsedTimeSecs,
    sessionDuration,
    voiceOverStages,
    outputDevice,
    playDemoVO,
}: Partial<AudioPlayerProps>): AudioPlayer {
    const playerRef = useRef<Promise<PlayerLibInterface | undefined>>(Promise.resolve(undefined));
    const [initialised, setInitialised] = useState(false);
    const [playbackStatus, setPlaybackStatus] = useState<'paused' | 'playing'>('paused');
    useLifecycles(
        () => {
            const player = new PlayerLibInterface();
            playerRef.current = player.init().then(() => {
                setInitialised(true);
                return player;
            });
        },
        () => {
            if (playerRef.current) {
                playerRef.current.then((p) => p?.destroy());
                playerRef.current = Promise.resolve(undefined);
            }
        },
    );

    const networkWakeHack = useNetworkWakeHack();

    const [volume, _setVolume] = useState(1);

    const hasRequiredProps = !!(streams && voiceOverStages && sessionDuration !== undefined);

    useEffect(() => {
        if (!hasRequiredProps) return;
        const streamsToPlay = makePlayerLibStreams(streams, voiceOverStages!, sessionDuration!, !!playDemoVO);
        console.log('streamsToPlay', streamsToPlay);
        playerRef.current.then((p) => p?.setSession(streamsToPlay));
    }, [hasRequiredProps, streams, voiceOverStages, playDemoVO, sessionDuration]);

    useEffect(() => {
        if (broadcastElapsedTimeSecs === undefined) return;
        playerRef.current.then((p) => p?.setBroadcastElapsedTimeSecs(broadcastElapsedTimeSecs));
    }, [broadcastElapsedTimeSecs]);

    useEffect(() => {
        if (!outputDevice) return;
        playerRef.current.then(async (p) => {
            if (!p) return;
            const audioCtx = await p.audioCtx();
            const audioCtxUntyped = audioCtx as any;
            // Chrome presents default as 'default' but setSinkId only accepts '' empty string
            const deviceId = outputDevice == 'default' ? '' : outputDevice;
            if (deviceId && 'setSinkId' in audioCtxUntyped) {
                audioCtxUntyped.setSinkId(deviceId);
            }
        });
    }, [outputDevice]);

    useEffect(() => {
        if ('mediaSession' in navigator) {
            navigator.mediaSession.setActionHandler('pause', () => {
                if (playbackStatus === 'playing') {
                    pause();
                }
            });
            navigator.mediaSession.setActionHandler('play', null);
            navigator.mediaSession.setActionHandler('seekbackward', null);
            navigator.mediaSession.setActionHandler('seekforward', null);
            navigator.mediaSession.setActionHandler('previoustrack', null);
            navigator.mediaSession.setActionHandler('nexttrack', null);
        }
    }, [playbackStatus]);

    const getCurrentTimeSecs = async () => {
        const p = await playerRef.current;
        const timeInPhase = await p?.getTimeInPhase();
        return timeInPhase ?? 0;
    };

    const getCurrentBufferSizeSecs = async () => {
        const p = await playerRef.current;
        const bufferedTime = await p?.getBufferedTime();
        return bufferedTime;
    };

    const play = async () => {
        if (!initialised || !hasRequiredProps) return;
        networkWakeHack.start();
        const p = await playerRef.current;
        const phase = await p?.getPhase();
        const isStarted = await p?.isStarted();
        if (phase !== PlayerLibStreamPhase.Session || !isStarted) {
            console.log('setting main phase');
            await p?.setPhase(PlayerLibStreamPhase.Session);
            await p?.start();
            setPlaybackStatus('playing');
            if ('mediaSession' in navigator) {
                navigator.mediaSession.playbackState = 'playing';
            }
        }
    };
    const playPrelude = async () => {
        if (!initialised || !hasRequiredProps) return;
        networkWakeHack.start();
        const p = await playerRef.current;
        const phase = await p?.getPhase();
        const isStarted = await p?.isStarted();
        if (phase !== PlayerLibStreamPhase.Pre || !isStarted) {
            await p?.setPhase(PlayerLibStreamPhase.Pre);
            await p?.start();
            setPlaybackStatus('playing');
            if ('mediaSession' in navigator) {
                navigator.mediaSession.playbackState = 'playing';
            }
        }
    };
    const playPostlude = async () => {
        if (!initialised || !hasRequiredProps) return;
        networkWakeHack.start();
        const p = await playerRef.current;
        const phase = await p?.getPhase();
        const isStarted = await p?.isStarted();
        if (phase !== PlayerLibStreamPhase.Post || !isStarted) {
            await p?.setPhase(PlayerLibStreamPhase.Post);
            await p?.start();
            setPlaybackStatus('playing');
            if ('mediaSession' in navigator) {
                navigator.mediaSession.playbackState = 'playing';
            }
        }
    };
    const pause = async () => {
        if (!initialised) return;
        networkWakeHack.stop();
        const p = await playerRef.current;
        await p?.stop();
        setPlaybackStatus('paused');
        if ('mediaSession' in navigator) {
            navigator.mediaSession.playbackState = 'paused';
        }
    };

    const setTime = async (timeWithinPhase: number) => {
        if (!hasRequiredProps) return;
        const p = await playerRef.current;
        await p?.seekToTimeInPhase(timeWithinPhase);
    };

    const unblock = async () => {
        const p = await playerRef.current;
        const ctx = await p?.audioCtx();
        ctx?.resume();
    };

    const setVolume = async (volume: number) => {
        _setVolume(volume);
        const p = await playerRef.current;
        await p?.setVolume(volume);
    };

    const end = async () => {
        const p = await playerRef.current;
        networkWakeHack.stop();
        await p?.stop();
        if ('mediaSession' in navigator) {
            navigator.mediaSession.playbackState = 'paused';
        }
    };

    return {
        type: 'playerlib',
        actions: { play, playPrelude, playPostlude, pause, setTime, unblock, setVolume, end },
        audioStatus: initialised ? (hasRequiredProps ? 'active' : 'init') : 'init',
        playerStatus: initialised ? (hasRequiredProps ? playbackStatus : 'loading') : 'loading',
        generalPlayerStatus: playbackStatus,
        warning: null,
        getCurrentTimeSecs,
        getCurrentBufferSizeSecs,
        volume,
        isVolumeControllable: true,
    };
}

function makePlayerLibStreams(
    streams: AudioPlayerStream[],
    voiceoverStages: VoiceoverStage[],
    sessionDurationMs: number,
    playDemoVO: boolean,
) {
    const result: PlayerLibStream[] = [];
    result.push({
        id: 'prelude',
        phase: PlayerLibStreamPhase.Pre,
        url: configs.freud.PRELUDE_POSTLUDE_MUSIC,
        fromTime: 0,
        toTime: LONG_TIME_FROM_NOW,
        fadeOutTime: STREAM_FADE_OUT_TIME,
        loopContent: true,
        gain: 1.0,
        usesSidechain: false,
        sidechainGain: 0.0,
    });
    result.push({
        id: 'postlude',
        phase: PlayerLibStreamPhase.Post,
        url: configs.freud.PRELUDE_POSTLUDE_MUSIC,
        fromTime: 0,
        toTime: LONG_TIME_FROM_NOW,
        fadeOutTime: STREAM_FADE_OUT_TIME,
        loopContent: true,
        gain: 1.0,
        usesSidechain: false,
        sidechainGain: 0.0,
    });
    result.push(
        ...streams.map((s) => ({
            id: s.id,
            phase: PlayerLibStreamPhase.Session,
            url: s.url,
            fromTime: s.fromTime,
            toTime: s.toTime,
            fadeOutTime: STREAM_FADE_OUT_TIME,
            loopContent: s.loopContent,
            gain: 1.0,
            usesSidechain: false,
            sidechainGain: 0.0,
        })),
    );
    for (const voiceover of voiceoverStages) {
        result.push({
            id: `vo-${voiceover.timing.from}-${voiceover.timing.to}-${voiceover.fileNameWithoutExtension}`,
            phase: PlayerLibStreamPhase.Session,
            url: `${configs.freud.CUSTOM_VOICEOVERS_PREVIEW_BASE_URL}${voiceover.fileNameWithoutExtension}.mp3`,
            fromTime: voiceover.timing.from * 1000,
            toTime: voiceover.timing.to * 1000,
            fadeOutTime: 0,
            loopContent: false,
            gain: voiceover.volume ?? 1.0,
            usesSidechain: true,
            sidechainGain: 1.0 - (voiceover.musicGain ?? 1.0),
        });
    }
    if (playDemoVO) {
        const DEMO_VO_INTERVAL_MINS = 20;
        for (const time of range(5000, sessionDurationMs, DEMO_VO_INTERVAL_MINS * 60 * 1000)) {
            result.push({
                id: `freeVo-${time}`,
                phase: PlayerLibStreamPhase.Session,
                url: configs.freud.FREE_ACCOUNT_VO,
                fromTime: time,
                toTime: time + 5 * 60 * 1000, // this assumes the free VO is no longer than 5 minutes long
                fadeOutTime: 0,
                loopContent: false,
                gain: 1.0,
                usesSidechain: true,
                sidechainGain: 0.8,
            });
        }
    }
    return result;
}
const useNetworkWakeHack = () => {
    const audioRef = useRef<HTMLAudioElement | null>(null);
    const hlsRef = useRef<Hls | null>(null);
    const hlsLoadedRef = useRef<boolean>(false);

    useEffect(() => {
        audioRef.current = new Audio();

        const setupHls = async () => {
            try {
                const Hls = await loadHls();
                if (Hls.isSupported()) {
                    hlsRef.current = new Hls();
                    if (audioRef.current) {
                        hlsRef.current.attachMedia(audioRef.current);
                        // Pre-load the silence playlist
                        hlsRef.current.loadSource('/silence/silence_playlist.m3u8');
                        hlsRef.current.on(Hls.Events.MANIFEST_PARSED, () => {
                            hlsLoadedRef.current = true;
                        });
                    }
                }
            } catch (err) {
                console.error('Failed to load Hls.js:', err);
            }
        };

        setupHls();

        return () => {
            if (audioRef.current) {
                audioRef.current.pause();
                audioRef.current = null;
            }

            if (hlsRef.current) {
                hlsRef.current.destroy();
                hlsRef.current = null;
            }
            hlsLoadedRef.current = false;
        };
    }, []);

    const start = () => {
        if (!audioRef.current || !hlsRef.current) return;
        audioRef.current.play().catch((err) => console.error('Failed to play silence:', err));
    };

    const stop = () => {
        if (audioRef.current) {
            audioRef.current.pause();
        }
    };

    return { start, stop };
};
