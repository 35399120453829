import { isEqual, omit, sortBy } from 'lodash';
import React, { createContext, memo, useEffect } from 'react';
import { BroadcastPersistentState, SessionRenderType, VoiceoverStage } from 'wavepaths-shared/core';

import configs from '../../configs';
import { useHLSAudioPlayer } from './useHLSAudioPlayer';
import { LONG_TIME_FROM_NOW, STREAM_FADE_OUT_TIME, usePlayerLibAudioPlayer } from './usePlayerLibAudioPlayer';

type PlayerStatus = 'idle' | 'paused' | 'playing' | 'error' | 'loading';
type AudioState = 'init' | 'blocked' | 'active';

export interface AudioPlayerStream {
    id: string;
    url: string;
    fromTime: number;
    toTime: number;
    loopContent: boolean;
}
export interface AudioPlayerProps {
    outputDevice: string | undefined;
    streams: AudioPlayerStream[];
    renderType: SessionRenderType;
    errorContext: string;
    mode: 'recording' | 'live';
    broadcastElapsedTimeSecs: number;
    sessionDuration: number;
    voiceOverStages: VoiceoverStage[];
    playDemoVO?: boolean;
}
export type LegacyAudioPlayerProps = Omit<AudioPlayerProps, 'streams'> & {
    broadcastIdentifier: string;
    broadcastState: BroadcastPersistentState;
};

export interface AudioPlayer {
    type: 'hls' | 'playerlib' | 'null';
    actions: {
        play: (props?: { fadeMs?: number; loop?: boolean; offsetSeconds?: number }) => void;
        playPrelude: () => void;
        playPostlude: () => void;
        pause: (props: { fadeMs?: number; reason: string }) => void;
        setTime: (seekToTimeSecs: number) => void;
        unblock: () => Promise<void>;
        setVolume: (vol: number) => void;
        end: () => void;
    };
    audioStatus: AudioState;
    playerStatus: PlayerStatus;
    generalPlayerStatus: 'paused' | 'playing';
    warning: string | null;
    getCurrentTimeSecs: () => Promise<number>;
    getCurrentBufferSizeSecs: () => Promise<number | undefined>;
    volume: number;
    isVolumeControllable: boolean;
}

export const NULL_AUDIO_PLAYER: AudioPlayer = {
    type: 'null',
    actions: {
        play: () => {
            console.log('null play');
        },
        playPrelude: () => {
            console.log('null playPrelude');
        },
        playPostlude: () => {
            console.log('null playPostlude');
        },
        pause: () => {
            console.log('null pause');
        },
        setTime: () => {
            console.log('null setTime');
        },
        unblock: () => Promise.resolve(),
        setVolume: () => {
            console.log('null setVolume');
        },
        end: () => {
            console.log('null end');
        },
    },
    audioStatus: 'init',
    playerStatus: 'idle',
    generalPlayerStatus: 'paused',
    warning: null,
    getCurrentTimeSecs: () => Promise.resolve(0),
    getCurrentBufferSizeSecs: () => Promise.resolve(undefined),
    volume: 0,
    isVolumeControllable: false,
};

const HLSAudioPlayer = ({
    playerProps,
    onPlayerChange,
}: {
    playerProps: LegacyAudioPlayerProps;
    onPlayerChange: (player: AudioPlayer) => void;
}) => {
    const player = useHLSAudioPlayer(playerProps);
    useEffect(() => {
        onPlayerChange({ type: 'hls', ...player });
    }, [player, onPlayerChange]);
    return null;
};

const PlayerLibAudioPlayer = ({
    playerProps,
    onPlayerChange,
}: {
    playerProps: LegacyAudioPlayerProps;
    onPlayerChange: (player: AudioPlayer) => void;
}) => {
    const timelineInOrder = sortBy(playerProps.broadcastState.timeline, 'dspOffset');
    const actualPlayerProps = {
        ...omit(playerProps, ['broadcastIdentifier', 'broadcastState']),
        streams: timelineInOrder.map((t, i) => ({
            id: t.sessionId,
            url: `${configs.freud.STREAM_BASE}/${playerProps.broadcastIdentifier}/${t.sessionId}/stream.m3u8`,
            fromTime: t.dspOffset,
            toTime:
                i === timelineInOrder.length - 1
                    ? LONG_TIME_FROM_NOW
                    : timelineInOrder[i + 1].dspOffset + STREAM_FADE_OUT_TIME,
            loopContent: false,
        })),
    };
    const player = usePlayerLibAudioPlayer(actualPlayerProps);
    useEffect(() => {
        onPlayerChange(player);
    }, [player, onPlayerChange]);
    return null;
};

export const AudioPlayerContext = createContext<AudioPlayer | undefined>(undefined);

// Working around react big time here, but should be short-lived code
// only for the duration of feature-flagging between the two audio players.

export type AudioPlayerTypeResolution = 'hls' | 'playerlib';

export const AudioPlayerMaker = memo<{
    playerTypeResolution?: AudioPlayerTypeResolution;
    playerProps: LegacyAudioPlayerProps;
    setAudioPlayer: (p: AudioPlayer) => void;
}>(({ playerProps, setAudioPlayer, playerTypeResolution = 'playerlib' }) => {
    const playerlibEnabled = playerTypeResolution === 'playerlib';
    return playerlibEnabled ? (
        <PlayerLibAudioPlayer playerProps={playerProps} onPlayerChange={setAudioPlayer} />
    ) : (
        <HLSAudioPlayer playerProps={playerProps} onPlayerChange={setAudioPlayer} />
    );
}, isEqual);
