import styled from '@emotion/styled';
import { Dialog, DialogContent, DialogTitle } from '@material-ui/core';
import React, { useContext } from 'react';
import { isScheduledWavepath, ScheduledWavepath, Session, Wavepath } from 'wavepaths-shared/core';
import { LayerInformation } from 'wavepaths-shared/types/content/core';

import { Button, EvaIcon, Typography } from '@/component-library';
import { Features } from '@/features';
import { useInstrumentPreview } from '@/hooks/useInstrumentPreview';
import { useWavePreview, useWavePreviewPlayer } from '@/hooks/useWavePreview';

import { useAuthContext } from '../../../auth';
import { RemoteSessionControlsContext } from '../../../pages/inSession/Guide';
import WavePathSelector from '../WavePathSelector';
import { useAvailableInstruments, useWaveInstruments } from './useWaveInstruments';
import { SKIP_WAVE_LABEL } from './WaveCard';
import { WaveCardHeaderContentContainer } from './WaveCardHeaderContentContainer';

const ExpandedContentContainer = styled.div({
    width: '100%',
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
});

export const WaveCardDialog = ({
    isOpen,
    wave,
    waveIndex,
    previousWave,
    shouldAllowAudioPreviews,
    onUpdateWave,
    onRemoveWave,
    onSkipToWave,
    onClose,
}: {
    isOpen: boolean;
    wave: Wavepath;
    waveIndex: number;
    previousWave?: Wavepath;
    shouldAllowAudioPreviews: boolean;
    onUpdateWave: (wave: Wavepath) => void;
    onRemoveWave: (e: any) => void;
    onSkipToWave?: (e: any) => void;
    onClose: () => void;
}) => {
    const remoteContext = useContext(RemoteSessionControlsContext);
    const { pathScore } = wave;
    const { name } = pathScore;
    const { isEnabled } = useAuthContext();
    const isInstrumentControlEnabled = isEnabled(Features.INSTRUMENT_CONTROL);
    return (
        <Dialog open={isOpen}>
            <DialogTitle>Edit wave</DialogTitle>
            <DialogContent>
                <>
                    {isInstrumentControlEnabled && isScheduledWavepath(wave) && (
                        <Instrumentation wavepath={wave} shouldAllowAudioPreviews={shouldAllowAudioPreviews} />
                    )}
                    <WaveCardHeaderContentContainer
                        name={name}
                        wave={wave}
                        previousWave={previousWave}
                        isOpen={isOpen}
                        pathScore={pathScore}
                        onRemoveWave={onRemoveWave}
                    />
                    <ExpandedContentContainer>
                        {onSkipToWave ? (
                            <Button style={{ marginBottom: 16 }} size="s" onClick={onSkipToWave} variant="outlined">
                                <EvaIcon name="skip-forward" size={16} />
                                <Typography variant="body2">{SKIP_WAVE_LABEL}</Typography>
                            </Button>
                        ) : null}
                        <WavePathSelector wavepath={wave} onUpdateWave={onUpdateWave} />
                    </ExpandedContentContainer>
                    <div style={{ display: 'flex', gap: '10px' }}>
                        {remoteContext &&
                            remoteContext.queuedFunction &&
                            remoteContext.queuedFunction.waveIndex === waveIndex &&
                            remoteContext.queuedFunction.showQueuingUIInline && (
                                <>
                                    <Button
                                        variant="solid-blue"
                                        onClick={() => {
                                            remoteContext.onSkipQueue();
                                            onClose();
                                        }}
                                    >
                                        Apply now or autosave in ...{' '}
                                        {remoteContext.queuedFunction.timeUntilExecutionInSeconds}s
                                    </Button>
                                    <Button
                                        variant="clear-underlined"
                                        onClick={() => {
                                            remoteContext.onCancelQueuedFunction();
                                        }}
                                    >
                                        Cancel edit
                                    </Button>
                                </>
                            )}
                        <Button variant="clear-underlined" onClick={onClose}>
                            Close
                        </Button>
                    </div>
                </>
            </DialogContent>
        </Dialog>
    );
};

/**
 * This is a placeholder UI
 */
const Instrumentation = ({
    wavepath,
    shouldAllowAudioPreviews,
}: {
    wavepath: ScheduledWavepath;
    shouldAllowAudioPreviews: boolean;
}) => {
    const { waveInstrumentation } = useWaveInstruments(wavepath);

    // Purely for testing at this point
    const availableBass = useAvailableInstruments(wavepath.pathScore.emotion, 'bass');
    const availableLowChords = useAvailableInstruments(wavepath.pathScore.emotion, 'lowChords');
    const availableLowMelody = useAvailableInstruments(wavepath.pathScore.emotion, 'lowMelody');
    const availableHighChords = useAvailableInstruments(wavepath.pathScore.emotion, 'highChords');
    const availableHighMelody = useAvailableInstruments(wavepath.pathScore.emotion, 'highMelody');
    const availableEmotiveChords = useAvailableInstruments(wavepath.pathScore.emotion, 'emotiveChords');
    const availableEmotiveMelody = useAvailableInstruments(wavepath.pathScore.emotion, 'emotiveMelody');
    const availableDeepen = useAvailableInstruments(wavepath.pathScore.emotion, 'deepen');
    const availableTrack = useAvailableInstruments(wavepath.pathScore.emotion, 'track');
    console.log({
        waveInstrumentation,
        availableBass,
        availableLowChords,
        availableLowMelody,
        availableHighChords,
        availableHighMelody,
        availableEmotiveChords,
        availableEmotiveMelody,
        availableDeepen,
        availableTrack,
    });

    const previewState = useWavePreview({ wavepath });
    return (
        <div>
            Preview state: {previewState.state.state}
            {previewState.state.state === 'notCreated' && <button onClick={() => previewState.render()}>Render</button>}
            {shouldAllowAudioPreviews && previewState.state.state === 'created' && (
                <WavePreviewPlayer session={previewState.state.session} />
            )}
            {shouldAllowAudioPreviews && (
                <InstrumentPreviews
                    instruments={[
                        ...availableBass,
                        ...availableLowChords,
                        ...availableLowMelody,
                        ...availableHighChords,
                        ...availableHighMelody,
                        ...availableEmotiveChords,
                        ...availableEmotiveMelody,
                        ...availableDeepen,
                        ...availableTrack,
                    ].map((i) => i.layers)}
                />
            )}
        </div>
    );
};

const WavePreviewPlayer = ({ session }: { session: Session }) => {
    const { state, play, pause } = useWavePreviewPlayer({ session });
    return (
        <div>
            {state.state === 'created' && <button onClick={play}>Play</button>}
            {state.state === 'playing' && <button onClick={pause}>Pause</button>}
        </div>
    );
};

const InstrumentPreviews = ({ instruments }: { instruments: LayerInformation[][] }) => {
    const { state, start, stop } = useInstrumentPreview();
    return (
        <>
            {instruments.slice(0, 0 /*none for now*/).map((instrument) => (
                <div key={instrument.map((i) => i.id).join(',')}>
                    {instrument[0].id}
                    {state.state !== 'notPlaying' && state.instrument === instrument && (
                        <button onClick={() => stop()}>Stop</button>
                    )}
                    {(state.state === 'notPlaying' || state.instrument !== instrument) && (
                        <button onClick={() => start(instrument)}>Play</button>
                    )}
                </div>
            ))}
        </>
    );
};
