import firebase from 'firebase';
import { last } from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import { useQueryParam } from 'use-query-params';
import { EndOfSessionFeedback, isScheduledWavepath, Wavepath } from 'wavepaths-shared/core';

import { useAuthContext } from '../../../auth';
import * as sessionApi from '../../../common/api/sessionApi';
import UserEvents from '../../../UserEvents';
import { AudioPlayerContext } from '../AudioPlayerProvider';
import { EndOfSessionFeedbackModal } from '../EndOfSessionFeedbackModal';
import { PersonalIntegrationModalContainer } from '../PersonalIntegrationModal';
import { OnlineContext } from '../useIsOnline';

export const PregenEndOfSessionModal: React.FC<{
    currentUser: firebase.User;
    sessionId: string;
    sessionOwner: string;
    wavepaths: Wavepath[];
    onAcknowledged?: () => void;
}> = React.memo(({ sessionId, sessionOwner, currentUser, wavepaths, onAcknowledged }) => {
    const [endOfSessionFeedback, setEndOfSessionFeedback] = useState<EndOfSessionFeedback>();
    const [feedbackDone, setFeedbackDone] = useState(false);

    const sessionDurationMs = last(wavepaths.filter(isScheduledWavepath))?.plan?.toTime ?? Number.MAX_SAFE_INTEGER;

    const [lessThanOneMinuteRemaining, setLessThanOneMinuteRemaining] = useState(false);
    const audioPlayerContext = useContext(AudioPlayerContext);
    useEffect(() => {
        const interval = setInterval(async () => {
            const elapsedTimeMs = (await audioPlayerContext?.getCurrentTimeSecs()) ?? 0;
            const timeRemaining = sessionDurationMs - elapsedTimeMs;
            setLessThanOneMinuteRemaining(timeRemaining < 1 * 60 * 1000);
        }, 1000);
        return () => clearInterval(interval);
    }, [sessionDurationMs, audioPlayerContext]);

    const { isOnline } = useContext(OnlineContext);

    const { isPersonal } = useAuthContext();
    const [sessionScreen, setSessionScreen] = useQueryParam('screen');
    const END_OF_SESSION_SCREEN = 'end';
    const isEndScreen = sessionScreen == END_OF_SESSION_SCREEN;
    const showModal = (lessThanOneMinuteRemaining || isEndScreen) && !feedbackDone && isOnline;

    if (showModal && !isEndScreen) {
        //persist the session screen state of the page if someone comes back after a while
        setSessionScreen(END_OF_SESSION_SCREEN);
    }

    const acknowledge = () => {
        setFeedbackDone(true);
        setSessionScreen(undefined);
        onAcknowledged && onAcknowledged();

        if (!isPersonal) {
            //only redirect if its a therapist, as for clients that page doesnt check for membership and freevo might trigger on "replay" button
            // so we keep clients here on this page so that recording works without free VO overlay
            window.location.href = `/session/details/${sessionId}`;
        }
    };

    if (!isPersonal) {
        const onSubmit = () => {
            if (endOfSessionFeedback) {
                sessionApi.sendPreviousSessionFeedback(sessionId, endOfSessionFeedback, currentUser);
                UserEvents.feedbackRecorded(endOfSessionFeedback);
                acknowledge();
            }
        };

        return showModal ? (
            <EndOfSessionFeedbackModal
                endOfSessionFeedback={endOfSessionFeedback}
                setEndOfSessionFeedback={setEndOfSessionFeedback}
                onSubmit={onSubmit}
                onSubmitAndEnd={onSubmit}
                onClose={acknowledge}
            />
        ) : (
            <></>
        );
    } else {
        return showModal ? (
            <PersonalIntegrationModalContainer
                sessionId={sessionId}
                sessionOwner={sessionOwner}
                onClose={acknowledge}
            />
        ) : (
            <></>
        );
    }
});
